export const main = {
  Name: 'main',
  Landing: {},
  Quiz: {
    quizFlow: [
      {
        type: 'info',
        image: '/static/images/samantha.png',
        heading: 'Your path to success begins here!',
        description:
          '<div style="text-align: left;">For more than 5 years, our group of top behavior specialists, health coaches, and accomplished business owners has developed the ideal quiz...<br /><br />A quiz that provides all the details needed to compose <strong>a completely personalized book</strong> designed to assist you in establishing the best routine.<br /><br />This innovative method will guide you to <strong>easily develop positive habits, discard negative ones, and realize your utmost abilities.</strong><br /><br />Keep in mind, your responses will determine the content of this book, so pay attention to the quiz and respond sincerely.</div>',
        button: 'Got it',
      },
      {
        question: 'Are you an early bird or a night owl?',
        questionCode: 'bird_owl',
        image: '/static/images/quiz_2.png',
        answers: ['Early bird', 'Night owl', 'I’m somewhere in between'],
        type: 'question',
      },
      {
        question: 'How many hours do you usually sleep?',
        questionCode: 'bird_owl',
        image: '/static/images/quiz_3.png',
        answers: ['Over 9 hours', '7-9 hours', '5-7 hours', '5 hours or less'],
        type: 'question',
      },
      {
        question: 'Understood! Do you wake up feeling refreshed and well-rested?',
        questionCode: 'wakeup_rested',
        image: '/static/images/quiz_4.png',
        answers: ['Always', 'Usually', 'Sometimes', 'Rarely'],
        type: 'question',
      },
      {
        question: 'How much time do you have in the morning before starting your day?',
        questionCode: 'time_in_morning',
        image: '/static/images/quiz_5.png',
        answers: [
          'Up to 10 minutes',
          '10-20 minutes',
          '20-30 minutes',
          'More than 30 minutes',
        ],
        type: 'question',
      },
      {
        question: 'How do you usually start your day?',
        questionCode: 'start_day',
        image: '/static/images/quiz_6.png',
        answers: [
          'Hitting the snooze button multiple times',
          'Scrolling social media on my phone',
          'With a routine (e.g. meditation, exercise, shower)',
          'Jumping straight into work',
        ],
        type: 'question',
      },
      {
        question: 'Got it! How satisfied are you with your current habits and routines?',
        questionCode: 'habits_routines',
        image: '/static/images/quiz_7.png',
        answers: ['Very satisfied', 'Somewhat satisfied', 'Not satisfied'],
        type: 'question',
      },
      {
        question:
          'When it comes to personal growth, what do you find the most challenging?',
        questionCode: 'personal_growth',
        image: '/static/images/quiz_8.png',
        answers: [
          'Figuring out what works best for me',
          'Keeping my motivation and consistency up',
          'Finding time for my personal growth',
        ],
        type: 'question',
      },
      {
        type: 'loading',
        heading: 'We’re now generating the first part of your book',
      },
      {
        type: 'info',
        heading: 'Benefits of your personalized Self Discovery book',
        description:
          '<div style="text-align: left;">✅ <strong>Personalized action strategy</strong> for effortlessly forming habits that fit your life<br /><br />✅ <strong>Optimized morning and night routines</strong> to boost your efficiency and energy<br /><br />✅ <strong>Specialized content</strong> focused on your specific requirements and passions<br /><br />✅ <strong>Engaging learning resources</strong> for rapid self-improvement<br /><br />✅ <strong>Expert guidance</strong> to steer you confidently through your journey of change<br /><br /></div>',
        button: 'Got it',
      },
      {
        question: 'How satisfied are you with your current fitness level?',
        questionCode: 'fitness_lvl',
        image: '/static/images/quiz_2.png',
        answers: [
          'Completely – I feel fit and healthy',
          'Somewhat – I would like to see some improvement',
          'Not at all – I want to see a major change',
        ],
        type: 'question',
      },
      {
        question: 'How often do you feel stressed out?',
        questionCode: 'stressed_out',
        image: '/static/images/quiz_3.png',
        answers: ['On a daily basis', 'Rarely', 'Sometimes', 'Never'],
        type: 'question',
      },
      {
        question: 'Do you struggle with any of these behaviors?',
        subtitle: 'If you’re not sure, go for Sometimes',
        questionCode: 'social_media',
        imageBelow: '/static/images/media.png',
        answers: ['No', 'Sometimes', 'Yes'],
        type: 'question',
      },
      {
        question: 'Do you struggle with any of these behaviors?',
        subtitle: 'If you’re not sure, go for Sometimes',
        questionCode: 'negative_self_talk',
        imageBelow: '/static/images/negative.png',
        answers: ['No', 'Sometimes', 'Yes'],
        type: 'question',
      },
      {
        question: 'Do you struggle with any of these behaviors?',
        subtitle: 'If you’re not sure, go for Sometimes',
        questionCode: 'disorganization',
        imageBelow: '/static/images/disorganization.png',
        answers: ['No', 'Sometimes', 'Yes'],
        type: 'question',
      },
      {
        question: 'Do you struggle with any of these behaviors?',
        subtitle: 'If you’re not sure, go for Sometimes',
        questionCode: 'procrastination',
        imageBelow: '/static/images/procrastination.png',
        answers: ['No', 'Sometimes', 'Yes'],
        type: 'question',
      },
      {
        question: 'Do you struggle with any of these behaviors?',
        subtitle: 'If you’re not sure, go for Sometimes',
        questionCode: 'bad_eating_habits',
        imageBelow: '/static/images/badeating.png',
        answers: ['No', 'Sometimes', 'Yes'],
        type: 'question',
      },
      {
        type: 'loading',
        heading: "We're now generating the second part of your book",
      },
      {
        type: 'info',
        image: '/static/images/join.png',
        maxWidth: '90%',
        subtitle: 'already enjoying the Self Discovery book',
        description:
          '<div style="text-align: left;"><i>"I\'m amazed at how this book speaks to me and fits seamlessly into my hectic life. It\'s as if the author understands exactly what I need. Talk about a surge in productivity! I\'ve discovered a routine that has eliminated stress and restored harmony in my life. For anyone looking to make real improvements, this is a must-read!"</i><br /><br />Alexa J., 38</div>',
        button: 'Got it',
      },
      {
        question: 'How often do you focus on the past or the future?',
        questionCode: 'past_future_focus',
        image: '/static/images/quiz_4.png',
        answers: ['Never – I live in the present', 'Sometimes', 'Often', 'Always'],
        type: 'question',
      },

      {
        question: 'How easily do you get distracted?',
        questionCode: 'easily_distracted',
        image: '/static/images/quiz_5.png',
        answers: [
          'I’m always laser-focused',
          'I rarely lose focus',
          'I sometimes lose focus',
          'I get easily distracted',
        ],
        type: 'question',
      },

      {
        question: 'Over the last year, were you successful in building good habits?',
        questionCode: 'good_habits_building',
        image: '/static/images/quiz_6.png',
        answers: [
          'I constantly improved my habits',
          'I built some good habits',
          'I tried but struggled to make them consistent',
          "I didn't try to build better habits",
        ],
        type: 'question',
      },
      {
        type: 'info',
        maxWidth: '400px',
        width: '100%',
        image: '/static/images/look_at.png',
        button: 'Got it',
      },

      {
        type: 'info',
        maxWidth: '400px',
        width: '100%',
        image: '/static/images/quiz_intro.png',
        button: 'Got it',
      },

      {
        question: 'How strong is your support system?',
        questionCode: 'support_system',
        image: '/static/images/quiz_7.png',
        answers: [
          'Very strong - I can rely on the people in my life',
          'Medium - I worry that people won’t be there',
          'Weak - I feel isolated',
        ],
        type: 'question',
      },

      {
        question: 'Almost done! Select the areas you are most interested in:',
        questionCode: 'interested_in',
        answers: [
          'Exercise',
          'Staying organized',
          'Proper nutrition and hydration',
          'Studying & work',
          'Mindfulness',
          'Better sleep',
          'Self-discipline',
          'Financial habits',
          'Gratitude',
          'Self-love',
          'Creativity',
          'Reading',
          'Parenthood',
          'Healthy aging',
          'Stoicism',
          'Pet lovers',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        question: 'Select the habits you’d like to quit:',
        questionCode: 'habits_quit',
        answers: [
          'Drinking',
          'Social media',
          'Self-doubt',
          'Daydreaming',
          'Binge eating',
          'Sleep procrastination',
          'Binge watching',
          'Smoking',
          'Procrastination',
          'Negative self-talk',
          'Nail biting',
          'Disorganization',
          'Overthinking',
          'Compulsive shopping',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        question: 'Select your main goals:',
        questionCode: 'main_goals',
        answers: [
          'Eat healthier',
          'Start working out',
          'Be more productive',
          'Overcome burnout',
          'Social growth',
          'Move on from disappointment',
          'Feel confident',
          'Reduce stress and anxiety',
          'Make tough decisions',
          'Achieve emotional control',
          'Spiritual growth',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        question: 'Almost done! Select celebrities you find inspiring:',
        questionCode: 'inspiring_celebs',
        answers: [
          'Oprah Winfrey',
          'Benjamin Franklin',
          'Ellen DeGeneres',
          'J.K. Rowling',
          'Michelle Obama',
          'Elon Musk',
          'Beyoncé',
          'Arnold Schwarzenegger',
          'Jennifer Aniston',
          'Jeff Bezos',
          'LeBron James',
          'Mark Zuckerberg',
          'Barack Obama',
          'Emma Watson',
        ],
        type: 'question',
        isMultiSelect: true,
      },

      {
        question: 'How much time would you like to dedicate to self-development?',
        questionCode: 'time',
        image: '/static/images/quiz_8.png',
        answers: ['5 min/day', '10 min/day', '15 min/day', '20+ min/day'],
        type: 'question',
      },

      {
        question: 'One last question! What is your age?',
        questionCode: 'age',
        image: '/static/images/quiz_2.png',
        answers: ['18-24', '25-34', '35-44', '45-54', '55+'],
        type: 'question',
      },
      {
        type: 'loading',
        heading: "We're now generating the last part of your book",
      },
      {
        type: 'input',
        heading: 'Finish personalizing your book',
        questionCode: 'name',
        placeholder: 'Enter your name',
        button: 'Preview',
        param: 'book',
      },
    ],
  },
  GetStarted: {
    description:
      'Our team has crafted a plan specifically tailored to your parenting needs. Ensure a brighter future for you and your child today.',
    button: 'Continue',
  },
  Summary: {
    heading: 'BASED ON YOUR ANSWERS, WE RECOMMEND A:',
    resultsTitle: 'Inner critic score',
    suggestedPlan: 'Parenting empowerment',
    issue: 'parental excellence',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Self-Improvement',
    promise: 'boost self-esteem',
    subtitle:
      'Fast-track your workflow, boost your sales, streamline your operations and and stay ahead of the curve.',
    benefits: [
      {
        heading: 'Build parental confidence',
        description: 'Equip yourself with the tools to face parenting challenges head-on',
      },
      {
        heading: 'Enhance your efficiency',
        description:
          'Master the art of multitasking and time management to be there for your child',
      },
      {
        heading: 'Solidify parental resolve',
        description:
          'Effective strategies for consistent parenting, even when the going gets tough',
      },
      {
        heading: 'Alleviate parental stress',
        description:
          'Methods for managing stress, ensuring a calmer home environment for your child',
      },
      {
        heading: "Become your child's hero",
        description:
          'Long-term strategies to build a stable, loving home for your child to thrive in',
      },
      {
        heading: 'Elevate family dynamics',
        description:
          'Strengthen communication and empathy within the family, creating a harmony',
      },
    ],

    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/cash.png',
        heading: 'Save time and money',
        description:
          'Streamline your workflow and optimize your resources. Our efficient tools and automated processes will save you valuable time and money, allowing you to focus on what truly matters for your business growth.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Increase conversion rate',
        description:
          'Experience a significant increase in your conversion rate. Our proven strategies and techniques will optimize your marketing efforts, resulting in higher conversion rates and greater business success.',
      },
      {
        image: '/static/icons/bed.png',
        heading: 'Boosted email revenue',
        description:
          'Unlock the potential of your email marketing campaigns. Our AI-based insights and personalized recommendations will help you craft compelling emails that engage your audience and drive revenue growth.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Ad concepts and angles',
        description:
          'Stand out from the competition. Our AI-powered platform generates innovative ad concepts and angles that capture attention, drive engagement, and ultimately boost your advertising effectiveness.',
      },
    ],
  },
};

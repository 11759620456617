export const trial = {
  Name: 'trial',
  Landing: {},
  Quiz: {
    quizFlow: [
      {
        type: 'info',
        image: '/static/images/samantha.png',
        heading: 'Your path to success begins here!',
        description:
          '<div style="text-align: left;">For more than 5 years, our group of top behavior specialists, health coaches, and accomplished business owners has developed the ideal quiz...<br /><br />A quiz that provides all the details needed to compose <strong>a completely personalized book</strong> designed to assist you in establishing the best routine.<br /><br />This innovative method will guide you to <strong>easily develop positive habits, discard negative ones, and realize your utmost abilities.</strong><br /><br />Keep in mind, your responses will determine the content of this book, so pay attention to the quiz and respond sincerely.</div>',
        button: 'Got it',
      },
      {
        question: 'Do you get up early or stay up late?',
        questionCode: 'early_late',
        image: '/static/images/quiz_2.png',
        answers: ['Get up early', 'Stay up late', 'I’m in the middle'],
        type: 'question',
      },

      {
        question: 'How many hours do you sleep on average?',
        questionCode: 'sleep_hours',
        image: '/static/images/quiz_3.png',
        answers: ['More than 9 hours', '7-9 hours', '5-7 hours', 'Less than 5 hours'],
        type: 'question',
      },

      // {
      // question: 'Do you feel fresh and ready to go when you wake up?',
      // questionCode: 'feel_refreshed',
      // image: '/static/images/quiz_4.png',
      // answers: ['Always', 'Mostly', 'At times', 'Seldom'],
      // type: 'question',
      // },

      {
        question: 'How much time do you spend in the morning before you begin your day?',
        questionCode: 'morning_time',
        image: '/static/images/quiz_5.png',
        answers: [
          'Up to 10 minutes',
          '10-20 minutes',
          '20-30 minutes',
          'More than 30 minutes',
        ],
        type: 'question',
      },

      {
        question: 'What’s your first activity in the morning?',
        questionCode: 'morning_activity',
        image: '/static/images/quiz_6.png',
        answers: [
          'Pressing the snooze button a lot',
          'Looking at social media on my phone',
          'Following a morning ritual (like meditation, working out, showering)',
          'Starting work immediately',
        ],
        type: 'question',
      },

      {
        question: 'Okay! How happy are you with your daily habits and routines?',
        questionCode: 'satisfaction_habits',
        image: '/static/images/quiz_7.png',
        answers: ['Very happy', 'Fairly happy', 'Unhappy'],
        type: 'question',
      },

      {
        question: 'What’s the toughest part about improving yourself?',
        questionCode: 'self_improvement',
        image: '/static/images/quiz_8.png',
        answers: [
          'Finding what suits me best',
          'Staying motivated and consistent',
          'Making time for self-improvement',
        ],
        type: 'question',
      },
      {
        type: 'loading',
        heading: 'We’re now generating the first part of your book',
      },
      {
        type: 'info',
        heading: 'Benefits of your personalized Self Discovery book',
        description:
          '<div style="text-align: left;">✅ <strong>Personalized action strategy</strong> for effortlessly forming habits that fit your life<br /><br />✅ <strong>Optimized morning and night routines</strong> to boost your efficiency and energy<br /><br />✅ <strong>Specialized content</strong> focused on your specific requirements and passions<br /><br />✅ <strong>Engaging learning resources</strong> for rapid self-improvement<br /><br />✅ <strong>Expert guidance</strong> to steer you confidently through your journey of change<br /><br /></div>',
        button: 'Got it',
      },
      // {
      //   question: "How pleased are you with your current level of fitness?",
      //   questionCode: "fitness_lvl",
      //   image: "/static/images/quiz_2.png",
      //   answers: [
      //     "Totally – I'm in good shape and healthy",
      //     "Partially – I'd like some improvement",
      //     "Not at all – I need a significant change"
      //   ],
      //   type: "question"
      // },
      {
        question: 'How often do you end up feeling stressed?',
        questionCode: 'stressed_out',
        image: '/static/images/quiz_3.png',
        answers: ['Every day', 'Seldom', 'Occasionally', 'Never'],
        type: 'question',
      },
      {
        question: 'Do you have trouble with any of these habits?',
        subtitle: 'Social media',
        questionCode: 'social_media',
        imageBelow: '/static/images/media.png',
        answers: ['No', 'Occasionally', 'Yes'],
        type: 'question',
      },
      {
        question: 'Do you have trouble with any of these habits?',
        subtitle: 'Negative self-talk',
        questionCode: 'negative_self_talk',
        imageBelow: '/static/images/negative.png',
        answers: ['No', 'Occasionally', 'Yes'],
        type: 'question',
      },
      {
        question: 'Do you have trouble with any of these habits?',
        subtitle: 'Disorganization',
        questionCode: 'disorganization',
        imageBelow: '/static/images/disorganization.png',
        answers: ['No', 'Occasionally', 'Yes'],
        type: 'question',
      },
      {
        question: 'Do you have trouble with any of these habits?',
        subtitle: 'Procrastination',
        questionCode: 'procrastination',
        imageBelow: '/static/images/procrastination.png',
        answers: ['No', 'Occasionally', 'Yes'],
        type: 'question',
      },
      {
        question: 'Do you have trouble with any of these habits?',
        subtitle: 'Bad eating habits',
        questionCode: 'bad_eating_habits',
        imageBelow: '/static/images/badeating.png',
        answers: ['No', 'Occasionally', 'Yes'],
        type: 'question',
      },
      {
        type: 'loading',
        heading: "We're now generating the second part of your book",
      },
      {
        type: 'info',
        image: '/static/images/join.png',
        maxWidth: '90%',
        subtitle: 'already enjoying the Self Discovery book',
        description:
          '<div style="text-align: left;"><i>"I\'m amazed at how this book speaks to me and fits seamlessly into my hectic life. It\'s as if the author understands exactly what I need. Talk about a surge in productivity! I\'ve discovered a routine that has eliminated stress and restored harmony in my life. For anyone looking to make real improvements, this is a must-read!"</i><br /><br />Alexa J., 38</div>',
        button: 'Got it',
      },
      // {
      //   question: "How often do you think about what was or what will be?",
      //   questionCode: "past_future_focus",
      //   image: "/static/images/quiz_4.png",
      //   answers: ["Never – I focus on the now", "Occasionally", "Frequently", "All the time"],
      //   type: "question"
      // },
      {
        question: 'How quickly do you lose concentration?',
        questionCode: 'easily_distracted',
        image: '/static/images/quiz_5.png',
        answers: [
          'I’m always completely focused',
          'I seldom get sidetracked',
          'I occasionally get sidetracked',
          "I'm easily sidetracked",
        ],
        type: 'question',
      },
      {
        question: 'In the past year, have you succeeded in forming positive habits?',
        questionCode: 'good_habits_building',
        image: '/static/images/quiz_6.png',
        answers: [
          'I continuously bettered my routines',
          'I developed some positive routines',
          'I attempted but found it hard to keep them up',
          "I didn't attempt to form better routines",
        ],
        type: 'question',
      },
      {
        type: 'info',
        maxWidth: '400px',
        width: '100%',
        image: '/static/images/look_at.png',
        button: 'Got it',
      },

      {
        type: 'info',
        maxWidth: '400px',
        width: '100%',
        image: '/static/images/quiz_intro.png',
        button: 'Got it',
      },

      // {
      //   question: "How solid is your backup group?",
      //   questionCode: "support_system",
      //   image: "/static/images/quiz_7.png",
      //   answers: [
      //     "Very solid - I trust my folks",
      //     "Okay - I sometimes worry if they'll show up",
      //     "Not strong - I often feel lonely"
      //   ],
      //   type: "question"
      // },
      {
        question: "Pick what you're really into:",
        questionCode: 'interested_in',
        answers: [
          'Working out',
          'Keeping things tidy',
          'Eating well and drinking water',
          'Studying & getting stuff done',
          'Chilling out',
          'Sleeping better',
          'Staying on track',
          'Saving money',
          'Being thankful',
          'Loving yourself',
          'Making stuff',
          'Reading books',
          'Being a parent',
          'Staying healthy as you get older',
          'Living simple',
          'Loving pets',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'Pick the habits you want to drop:',
        questionCode: 'habits_quit',
        answers: [
          'Drinking booze',
          'Too much social media',
          'Second-guessing yourself',
          'Daydreaming too much',
          'Eating too much junk',
          'Staying up too late',
          'Watching too much TV',
          'Smoking',
          'Putting things off',
          'Talking down to yourself',
          'Chewing your nails',
          'Being messy',
          'Thinking too much',
          'Shopping without thinking',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'What are your big goals?',
        questionCode: 'main_goals',
        answers: [
          'Eat better',
          'Get moving',
          'Do more in less time',
          'Feel less tired',
          'Meet new people',
          'Get over bad news',
          'Feel good about yourself',
          'Chill out more',
          'Make big choices',
          'Keep your cool',
          'Grow on the inside',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      // {
      //   question: "Pick famous people you look up to:",
      //   questionCode: "inspiring_celebs",
      //   answers: [
      //     "Dwayne Johnson",
      //     "Malala Yousafzai",
      //     "Keanu Reeves",
      //     "Stephen King",
      //     "Serena Williams",
      //     "Steve Jobs",
      //     "Adele",
      //     "Chris Pratt",
      //     "Taylor Swift",
      //     "Warren Buffet",
      //     "Simone Biles",
      //     "Stephen Hawking",
      //     "Ruth Bader Ginsburg",
      //     "Dalai Lama"
      //   ],
      //   type: "question",
      //   isMultiSelect: true
      // },
      {
        question: 'How long can you give to getting better every day?',
        questionCode: 'time',
        image: '/static/images/quiz_8.png',
        answers: ['5 mins/day', '10 mins/day', '15 mins/day', '20+ mins/day'],
        type: 'question',
      },

      {
        question: 'One last question! What is your age?',
        questionCode: 'age',
        image: '/static/images/quiz_2.png',
        answers: ['18-24', '25-34', '35-44', '45-54', '55+'],
        type: 'question',
      },
      {
        type: 'loading',
        heading: "We're now generating the last part of your book",
      },
      {
        type: 'input',
        heading: 'Finish personalizing your book',
        questionCode: 'name',
        placeholder: 'Enter your name',
        button: 'Preview',
        param: 'book',
      },
    ],
  },
  GetStarted: {
    description:
      'Our team has crafted a plan specifically tailored to your parenting needs. Ensure a brighter future for you and your child today.',
    button: 'Continue',
  },
  Summary: {
    heading: 'BASED ON YOUR ANSWERS, WE RECOMMEND A:',
    resultsTitle: 'Inner critic score',
    suggestedPlan: 'Parenting empowerment',
    issue: 'parental excellence',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Self-Improvement',
    promise: 'boost self-esteem',
    subtitle:
      'Fast-track your workflow, boost your sales, streamline your operations and and stay ahead of the curve.',
    benefits: [
      {
        heading: 'Build parental confidence',
        description: 'Equip yourself with the tools to face parenting challenges head-on',
      },
      {
        heading: 'Enhance your efficiency',
        description:
          'Master the art of multitasking and time management to be there for your child',
      },
      {
        heading: 'Solidify parental resolve',
        description:
          'Effective strategies for consistent parenting, even when the going gets tough',
      },
      {
        heading: 'Alleviate parental stress',
        description:
          'Methods for managing stress, ensuring a calmer home environment for your child',
      },
      {
        heading: "Become your child's hero",
        description:
          'Long-term strategies to build a stable, loving home for your child to thrive in',
      },
      {
        heading: 'Elevate family dynamics',
        description:
          'Strengthen communication and empathy within the family, creating a harmony',
      },
    ],

    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/cash.png',
        heading: 'Save time and money',
        description:
          'Streamline your workflow and optimize your resources. Our efficient tools and automated processes will save you valuable time and money, allowing you to focus on what truly matters for your business growth.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Increase conversion rate',
        description:
          'Experience a significant increase in your conversion rate. Our proven strategies and techniques will optimize your marketing efforts, resulting in higher conversion rates and greater business success.',
      },
      {
        image: '/static/icons/bed.png',
        heading: 'Boosted email revenue',
        description:
          'Unlock the potential of your email marketing campaigns. Our AI-based insights and personalized recommendations will help you craft compelling emails that engage your audience and drive revenue growth.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Ad concepts and angles',
        description:
          'Stand out from the competition. Our AI-powered platform generates innovative ad concepts and angles that capture attention, drive engagement, and ultimately boost your advertising effectiveness.',
      },
    ],
  },
};

import { main } from './Funnels/main';
import { trial } from './Funnels/trial';
import { IProduct } from '../../types';
import { findProductBySku } from '../../helpers/products';

export const Funnels = {
  main,
  trial,
  default: main,
};

export const FunnelRoutes = {
  HOME: '/',
  QUIZ: '/quiz',
  CHECKING: '/checking',
  GET_STARTED: '/get-started',
  SUMMARY: '/summary',
  PURCHASE_PLAN: '/purchase-plan',
  CHECKOUT: '/checkout',
  DELIVERY: '/delivery',
  UPSELL_1: '/cheatcode-upsell',
  UPSELL_2: '/premium-upsell',
  UPSELL_3: '/mental-health-workbook',
  UPSELL_4: '/annual-plan-upgrade',
  REGISTER: '/register',
  THANK_YOU: '/thank-you',
  EMAIL_OFFER: '/email-offer',
  TRIAL_OFFER: '/trial-offer',
};

export const UpsellsOrder = {
  default: [
    FunnelRoutes.UPSELL_4,
    FunnelRoutes.UPSELL_3,
    FunnelRoutes.UPSELL_1,
    FunnelRoutes.UPSELL_2,
  ],
};

export const RoutesOrder = {
  // default: [
  //   FunnelRoutes.HOME,
  //   FunnelRoutes.QUIZ,
  //   FunnelRoutes.CHECKING,
  //   FunnelRoutes.GET_STARTED,
  //   FunnelRoutes.SUMMARY,
  //   FunnelRoutes.PURCHASE_PLAN,
  //   FunnelRoutes.CHECKOUT,
  //   FunnelRoutes.DELIVERY,
  //   FunnelRoutes.REGISTER,
  //   FunnelRoutes.THANK_YOU,
  // ],
  default: [
    FunnelRoutes.HOME,
    FunnelRoutes.QUIZ,
    FunnelRoutes.CHECKING,
    FunnelRoutes.GET_STARTED,
    FunnelRoutes.TRIAL_OFFER,
    FunnelRoutes.DELIVERY,
    FunnelRoutes.REGISTER,
    FunnelRoutes.THANK_YOU,
  ],
  trial: [
    FunnelRoutes.HOME,
    FunnelRoutes.QUIZ,
    FunnelRoutes.CHECKING,
    FunnelRoutes.GET_STARTED,
    FunnelRoutes.TRIAL_OFFER,
    FunnelRoutes.DELIVERY,
    FunnelRoutes.REGISTER,
    FunnelRoutes.THANK_YOU,
  ],
};

export const ProductSkus = {
  sixMonthPlan_SD: 'sixMonthPlan_SD',
  threeMonthPlan_SD: 'threeMonthPlan_SD',
  oneMonthPlan_SD: 'oneMonthPlan_SD',
  bookPaperback: 'bookPaperback',
  sixMonthPlan_email_SD: 'sixMonthPlan_email_SD',
  threeMonthPlan_email_SD: 'threeMonthPlan_email_SD',
  oneMonthPlan_email_SD: 'oneMonthPlan_email_SD',
  bookPaperbackEmail: 'bookPaperbackEmail',
  eBook: 'eBook',
  premiumUpsell: 'premiumUpsell',
  cheatcodeUpsell: 'cheatcodeUpsell',
  mentalHealthWorkbookUpsell: 'mentalHealthWorkbookUpsell',
  annualPlanUpgrade: 'annualPlanUpgrade',
  threeMonthsTrialPlan_SD: 'threeMonthsTrialPlan_SD',
};

export const getProducts = (products: IProduct[]) => ({
  sixMonthPlan_SD: findProductBySku(products, ProductSkus.sixMonthPlan_SD),
  sixMonthPlan_email_SD: findProductBySku(products, ProductSkus.sixMonthPlan_email_SD),
  threeMonthPlan_SD: findProductBySku(products, ProductSkus.threeMonthPlan_SD),
  threeMonthPlan_email_SD: findProductBySku(
    products,
    ProductSkus.threeMonthPlan_email_SD,
  ),
  oneMonthPlan_SD: findProductBySku(products, ProductSkus.oneMonthPlan_SD),
  oneMonthPlan_email_SD: findProductBySku(products, ProductSkus.oneMonthPlan_email_SD),
  bookPaperback: findProductBySku(products, ProductSkus.bookPaperback),
  bookPaperbackEmail: findProductBySku(products, ProductSkus.bookPaperbackEmail),
  eBook: findProductBySku(products, ProductSkus.eBook),
  premiumUpsell: findProductBySku(products, ProductSkus.premiumUpsell),
  cheatcodeUpsell: findProductBySku(products, ProductSkus.cheatcodeUpsell),
  mentalHealthWorkbookUpsell: findProductBySku(
    products,
    ProductSkus.mentalHealthWorkbookUpsell,
  ),
  annualPlanUpgrade: findProductBySku(products, ProductSkus.annualPlanUpgrade),
  threeMonthsTrialPlan_SD: findProductBySku(
    products,
    ProductSkus.threeMonthsTrialPlan_SD,
  ),
});

export const ProjectName = 'Self Discovery';
